@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding:0;
}
body {
  margin: 0;
  padding: 0;
}

.button{
  cursor:pointer;
  background-color: #001d34;
  color: white;
  border-radius: 4px;
}
.button:hover{
  color: #52c0e6;
}
.button-green{
  cursor:pointer;
  background-color: #001d34;
  color: white;
  border-radius: 4px;
}
.button-green:hover{
  color: #52c0e6;
}
.image{
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}
/* colors */
.bg-abbott-black{
  background-color: #333;
}
.bg-abt-blue-dark{
  background-color: #001d34;
}
.bg-abbott-gray{
  background-color: #d9d9d7;
}
.bg-abt-blue{
  background-color: #52c0e6;
}
.bg-abt-red{
  background-color: #ef3827;
}
.bg-abt-red-50{
  background-color: #fe9a8b;
}
.color-abt-blue{
  color: #52c0e6;
}
.color-abt-blue-dark{
  color: #001d34;
}
.color-abt-gray{
  color: #d9d9d7;
}
.color-abt-gray-dark{
  color:#888b8d;
}
.color-abt-green{
  color: #00b156;
}
.color-abt-red{
  color: #ef3827;
}
.color-abt-gold{
  color: #fcb53b;
}
.border-abt-blue-dark{
  border-color: #001d34;
}

/* Dropdown Items */
.dropbtn {
  background-color: #001d34;
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a,
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover,
.dropdown-content div:hover {
  background-color: #d9dadb;
  color: #001d34;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: #52c0e6;
}


/* ToolTip Items */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  top:50px;
  left:0px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #888b8d;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #52c0e6;
}

input:focus + .slider {
  box-shadow: 0 0 1px #52c0e6;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.style-transition{
  transition: opacity .5s;
}
/* buttons */
.btn{
  user-select: none;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}
.border-btn{
  font-size: 1vw;
  user-select: none;
  cursor: pointer;
  text-align: center;
  padding:10px;
  margin:5px;
  border-radius: 10px;
  background-color: #fff;
}
.border-btn:hover{
  color:#FFF;
}

.green-btn{
  border:1px solid #00b156;
  color:#00b156
}
.green-btn:hover{
  background-color:#00b156;
}

.red-btn{
  border:1px solid #ef3827;
  color:#ef3827
}
.red-btn:hover{
  background-color:#ef3827;
}

.blue-btn{
  border:1px solid #52c0e6;
  color:#52c0e6
}
.blue-btn:hover{
  background-color:#52c0e6;
}