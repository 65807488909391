/* buttons */
.button-abt-blue{
    color: #52c0e6;
    border-color: #52c0e6;
  }
  .button-abt-blue:hover{
    background-color: #52c0e6;
  }

  /* icons */
.character-icon{
    text-align: center;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: 16px;
    line-height: 17px;
    cursor: default;
    margin-right: 5px;
    float: left;
    font-weight: 500;
  }