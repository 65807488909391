.abbott-logo-icon{
    
    background-position: center;

}

.bg-green-lite{
    background-color: #84c778;
}

.cancel-btn {
    background-image: url('../../assets/images/notepad/draw-cancel.png');
}

.clear-btn {
    background-image: url('../../assets/images/notepad/draw-clear.png');
}

.undo-btn {
    background-image: url("../../assets/images/notepad/draw-undo.png")
}

.save-btn {
    background-image: url("../../assets/images/notepad/draw-save.png");
}


.text-btn {
    background-image: url("../../assets/images/notepad/icon-text.svg");
    background-color: #912DE3;
}

.text-btn-disabled {
    background-color: #d6b5ee;
}


.upload-success {
    position: absolute;
    background-image: url('../../assets/images/notepad/draw-success.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 400px 400px;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 100px;
    z-index: 21;
    background-color: #76ca6e;
}

.abbott-logo{
    background-image: url('../../assets/images/notepad/logo.png');
  }
  
  /* Drop Down */
  .dropdown-parent{
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .dropdown-child{
    visibility: hidden;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .dropdown-child ul {
    list-style-type: none;
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid #001d34;
    background-color: #FFF;
    font-size: 22px;
    width: 250px;
  }
  
  .dropdown-parent:hover .dropdown-child{
    visibility: visible;
  }
  
  .dropdown-off{
    visibility: hidden;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  } 
  
  .dd-parent-button {
    border: none;
    font-size: 24px;
    resize: none;
    margin-top: 5px;
  }
  
  .dd-child-button {
    padding: 10px;
  }
  
  .success{
    position: absolute;
    top:0;
    left:0;
    color:white;
    font-size: 42px;
    font-family: Tahoma, sans-serif;
    text-align: center;
  }
  .success img{
    width: 400px;
    height: 400px;
    margin: auto;
    margin-top: 100px;
  }



  .square-button {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    border: 0px;
    border-radius: 10px;
    cursor: pointer;
    width: 60px;
    height: 60px;
    margin: 2px;
    text-align: center;
    line-height: 1;
  }
  
  .tile-text {
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid #001d34;
    background-color: #FFF;
    border-radius: 4px;
    font-size: 24px;
    resize: none;
  }
  /* Spinner */
  .spinner {
    border-radius: 50%;
    border: 40px solid #00000010;
    border-top: 40px solid #84c778;
    border-bottom: 40px solid #84c77850;
    width: 200px;
    height: 200px;
    margin: auto;
    margin-top: 200px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* modal; */
  
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .modal-text-editor {
    background-color: #f2f3f5;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .close {
   display: flex;
   
    justify-content: flex-end;
    align-items: center;
   
  }
  
  .close-btn {
    width: fit-content;
    border: 1px solid #000;
    border-radius: 15px;
    padding: 5px 7px;
    background-color: #fff;
  
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  @media (max-width: 640px) {
    .container {max-width: 640px;}
  }
  @media (max-width: 768px) {
    .container {max-width: 768px;}
  }
  @media (max-width: 1024px) {
    .container {max-width: 1024px;}
  }
  @media (max-width: 1280px) {
    .container {max-width: 1280px;}
  }
  @media (max-width: 1536px) {
    .container {max-width: 1536px;}
  }