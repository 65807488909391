.background{
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.tile{
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  opacity : 0;
  transition: opacity .3s;
}
.tile-border{
  border: 1px solid #F8F8F8;
}
.abbott-mask{
  position:absolute;
}
.image-scale{
  position: absolute;
  display: block;
}