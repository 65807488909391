.tab-btn{
    font-size: 12px;
    padding: 2px 8px 5px 8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border:1px solid #52c0e6;
    border-bottom-color: #52c0e6;
    user-select: none;
    cursor: pointer;
  }
  .tab-btn:hover{
    background-color:#f2f3f5;
  }
  .tab-btn-selected{
    background-color:#52c0e6;
    border-bottom-color: #52c0e6;
  }
  .tab-btn-selected:hover{
    background-color:#52c0e6;
    border-bottom-color: #52c0e6;
  }
  .animation-btn{
    font-size: 1.1vw;
    user-select: none;
    cursor: pointer;
    text-align: center;
    padding:10px;
    margin:5px;
    border:1px solid #52c0e6;
    border-radius: 10px;
    background-color: #fff;
  }

  .animation-btn:hover{
    background-color:#52c0e6;
    color:#FFF;
  }
  .animation-btn-selected,
  .animation-btn-selected:hover{
    background-color:#52c0e6;
    color:#001d34;
    border:1px solid #001d34;
    cursor: default;
  }